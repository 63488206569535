<template>
  <div style="margin-top: 4vh" class="container">
    <div>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item :title="Subtype" name="3">
          <div class="row myTitle" style="margin-top: 3vh">
            <div class="col-lg-5 col-5">
              <span><img src="../../assets/myImage/hat.png" alt="">&nbsp;&nbsp;&nbsp;</span>
              <span>{{ Subtype }}</span>
            </div>
            <div class="col-lg-3 col-3">
              <span>学制</span>
            </div>
            <div class="col-lg-4 col-4">
              <span>学费</span>
            </div>
          </div>
          <div style="margin-top: 3vh" v-for="item of subjects">
            <div class="row myClass" >
              <div class="col-5 col-lg-5">{{ item.name_chs }}</div>
              <div class="col-3 col-lg-3">{{ item.subject_duration }}月</div>
              <div class="col-4 col-lg-4">${{ Math.floor(item.subject_fee)  }}<el-button @click="toSubject(item)" type="primary" size="mini" style="margin-left: 50px">课程详情</el-button></div>
            </div>
            <hr class="row">
          </div>
        </el-collapse-item>
      </el-collapse>

    </div>
  </div>
</template>

<script>
export default {
  name: "classCard",
  props:['Subtype','subjects'],
  data(){
    return{
      showBtn:true,
      activeNames: ['3']
    }
  },
  methods:{
    toSubject(item){
      console.log(item)
      this.$router.push({path:'/majorIntroduce',query:{item:JSON.stringify(item)}})
    }
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
hr{
  opacity: .5;
}
.myClass{
  margin-top: 10px;
  div{
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
</style>