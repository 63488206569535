<template>
  <div class="showSearch">
    <div v-if="have">
      <div v-if="val==='院校'" class="school">
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between;margin-top: 2vw">
          <schoolCard class="schoolCard"
                      :key="item.id"
                      v-for="item in schoolData.results"
                      :img="item.school_logo"
                      :message="item.sub_title"
                      :title="item.name_chs"
                      :subTitle="item.name_eng"
                      :rank="typeof(item.rank) == 'number'?item.rank:'暂无'"
                      @click.native="toIntroduce(item)"
                      style="margin-top: 1.5vw"
          ></schoolCard>
        </div>
        <div class="myBtn" @click="getNext(schoolData)">查看更多</div>
      </div>

      <div v-else-if="val==='专业'" class="info" style="width: 90%;margin: 0 auto">
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
          <majorCard
              class="card"
              style="margin-top: 1.5vw"
              v-for="item of majorData.results"
              :key="item.id"
              :img2="item.school.school_logo"
              :item="item"
              ></majorCard>
        </div>
        <div class="myBtn" @click="getNext(majorData)">查看更多</div>
      </div>

      <div v-else-if="val==='资讯'" class="info">
        <div style="margin-top: 1.5vw">
          <div class="hotContent-right">
            <div class="hot-right" v-for="item of zixunData.results" :key="item.id">
              <div class="hot-right-left">
                <div style="height: 100%;width: 95%">
                  <img :src="item.cover_image" alt="">
                </div>
              </div>
              <div class="hot-right-right">
                <h4 @click="toUrl(item.url)">{{ item.article_name }}</h4>
                <p>{{ item.short_intro }}</p>
                <p class="time"><b-icon icon="stopwatch"></b-icon>&nbsp;&nbsp;&nbsp;{{ item.create_date }}</p>
              </div>
            </div>
          </div>
          <div class="myBtn" @click="getNext(zixunData)">查看更多</div>
        </div>
      </div>
    </div>

    <h3 v-else style="text-align: center;margin-top: 3vw">没有查询到信息~~~</h3>
  </div>
</template>

<script>
import axios from "axios";
import {constants} from "@/services/constants";

import schoolCard from "@/components/showSchool/schoolCard";
import majorCard from "@/components/majorCard/majorCard";

export default {
  name: "showSearch",
  components:{
    schoolCard,
    majorCard
  },
  data(){
    return{
      val:'',
      val1:'',
      have:true,
      schoolData:'',
      zixunData:'',
      majorData:''
    }
  },
  watch:{
    $route:{//监听路由获取要搜索的值变化，并做出相应的请求与页面渲染
      handler(newRoute){
        this.val = newRoute.query.val
        this.val1 = newRoute.query.val1
        if (this.val1 === ''){
          alert('请输入要搜索的内容')
        }else {
          axios({
            method:'post',
            url:constants.SEARCH,
            headers: {
              'content-type': 'application/json',
              'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
            },
            data: {
              type: this.val,
              content:this.val1
            }
          }).then((res) => {
            console.log(res.data.results.length)
            if (res.data.results.length === 0){//如果没有搜索到数据则返回没有结果节点
              this.have = false
            }else {
              this.have = true
              if (this.val === '院校'){
                this.schoolData = res.data
                console.log(this.schoolData)
              }else if(this.val === '资讯'){
                this.zixunData = res.data
                console.log(this.zixunData)
              }else if(this.val === '专业'){
                this.majorData = res.data
                console.log(1,this.majorData)
              }
            }
          })
        }
      },
      immediate:true
    }
  },
  methods:{
    getNext(data){//封装查看更多方法
      if (data.results.length < data.count){//加入判断，如果展示完了所有，那么就不再发送请求
        axios({
          method:'post',
          url:data.next,
          headers: {
            'content-type': 'application/json',
            'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
          },
          data: {
            type: this.val,
            content:this.val1
          }
        }).then((res) => {
          data.results = data.results.concat(res.data.results)
        })
      }else {
        alert('没有更多信息了~~~')
      }

    },
    toIntroduce(item) {//跳转到点击学校的详细介绍
      this.$router.push({path:'/schools/schoolIntroduce',query:{id:item.id}})
    },
    toUrl(url){//打开资讯
      window.open(url,"_blank")
    }
  }
}
</script>

<style scoped lang="scss">
//*{
//  outline: 1px solid red;
//}
.schoolCard{
  border-radius: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.showSearch{
  width: 80%;
  margin: 0 auto;
}
h4:hover{
  cursor: pointer;
  text-decoration: underline;
}
.hotContent-right{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .hot-right{
    width: 49%;
    background-color: #F8F8F8;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    .hot-right-left{
      width: 43%;
      height: 10vw;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .hot-right-right{
      padding-right: 1vw;
      margin-top: .5vw;
      width: 57%;
    }
  }
}
</style>
