<template>
  <div>
    <div class="schoolIntroduce">
      <div @click="goMain" style="
  margin-top: 2vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 1vw;
"><&nbsp;&nbsp;返回上一页</div>
      <div style="font-size: 1.3vw;font-weight: bold;margin-top: 1.5vw">{{ schoolData.name_chs }}</div>
      <div class="container myC">

        <div class="row">
          <div class="col-lg-8">
            <div class="schoolVedio">
<!--              <img :src="schoolImg" alt="">-->
              <el-carousel height="33vw">
                <el-carousel-item v-for="item in schoolImg" :key="item.id">
                  <!--                    <h3 class="small">{{ item }}</h3>-->
                  <img :src="item.img" alt="">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="CollegeProfile">
              <h3>院校简介</h3>
              <div class="Info">
                <span>QS排名</span>
                <div><span>{{schoolData.rank}}</span></div>
              </div>
              <hr>
              <div class="Info">
                <span>学校性质</span>
                <div><span v-for="item of schoolData.school_types" :key="item.id">&nbsp;{{ item.type }}</span></div>
              </div>
              <hr>
              <div class="Info">
                <span>课程设置</span>
                <div v-if="haveSubjects.length === 0">
                  <span>暂无课程</span>
                </div>
                <div v-else>
                  <span v-for="item of haveSubjects" :key="item.id">&nbsp;{{ item.type }}</span>
                </div>
              </div>
              <hr>
              <div class="Info">
                <span>每年学费</span>
                <div><span>$17750~$63750</span></div>
              </div>
              <hr>
              <div class="Info">
                <span>每年入取时间</span>
                <div><span>1月/8月</span></div>
              </div>
              <hr>
              <div class="address">地址:{{schoolData.address}}</div>
            </div>
          </div>
        </div>

        <div class="row introduce">
<!--          <v-md-preview :text="text"></v-md-preview>-->

          <mavon-editor
              v-model="text"
              :subfield="false"
              :box-shadow="false"
              default-open="preview"
              :toolbars-flag="false"
              preview-background="white"
          ></mavon-editor>
        </div>

        <div class="row" style="margin-top: 3vw">
          <div class="col-lg-8">
            <h3 style="margin-bottom: 25px">课程及学费</h3>
            <div v-show="haveSubjects.length === 0">
              <h2>暂无课程！！！</h2>
            </div>

            <classCard v-for="(item,index) of haveSubjects" :Subtype="item.type" :subjects="item.subjects" :key="index"></classCard>
            <div style="text-align: center;margin-top: 2.5vw;text-decoration: underline;opacity: .5">没有更多课程了～～</div>
          </div>

          <div class="col-lg-4 container" style="margin: 0 auto;padding: 10px">
            <div class="row">
              <div class="materials col-lg-10">
                <h3>
                  <img src="../assets/myImage/document.png" alt="">
                  申请材料
                </h3>
                <p>1. 护照信息，尚未取得护照的外国申请者可呈交出生证明或户籍证明</p>
                <p>2. 最高学历的成绩单以及毕业证或在读证明</p>
                <p>3.  初中毕业成绩单（例如中国中考，新加坡剑桥O水准，IGCSE等）</p>
                <p>4. 语言等其他额外要求的考试成绩单明</p>
                <div>申请所要求的材料会根据申请人递交的最高学历有所不同.</div>
              </div>
            </div>
            <div class="row" style="margin-top: 40px">
              <div class="col-lg-10" style="margin: 0 auto">
                <h3>学校新闻</h3>
                <schoolNews
                    v-for="item in newData.slice(4,7)"
                    :url="item.url"
                    :img="item.cover_image"
                    :createDate="item.create_date"
                    :title="item.article_name"></schoolNews>
              </div>
            </div>
            <div class="row" style="margin-top: 40px">
              <div class="col-lg-10" style="margin: 0 auto">
                <img width="100%" src="../assets/myImage/teachyou.png" alt="">
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
import classCard from "@/components/classCard/classCard";
import schoolNews from "@/components/schoolNews";

import FormTeacherGroup from "@/components/sections/FormTeacherGroup";
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import {schoolService} from "@/services/school";


import "/node_modules/github-markdown-css/github-markdown.css"


export default {
  name: "schoolIntroduce",
  components:{
    Header,
    OffCanvasMobileMenu,
    classCard,
    schoolNews,
    FormTeacherGroup
  },
  data(){
    return{
      schoolImg:[],
      newData:'',
      schoolData:'',
      subjectLeves:'',
      haveSubjects:'暂无',
      converter:'',
      htmlText:'',
      text:''

    }
  },
  created() {


    schoolService.getSchoolSubjects(this.$route.query.id).then((data)=>{//获取学校课程
      this.haveSubjects = data.filter((item) => {
        return item.subjects.length !== 0//返回有开设的课程
      })
    })

    httpService.get(constants.SCHOOL_LIST+this.$route.query.id).then((data)=>{//获取当前点击学校信息
      this.schoolData = data.body
      this.schoolImg = data.body.school_env_images
      console.log(this.schoolData)
      this.text = this.schoolData.introduction
      // this.htmlText = this.converter.makeHtml(this.text);
    })



    this.newData = JSON.parse(window.sessionStorage.getItem('blogData')).results
    console.log(this.newData)
  },

  methods:{

    goMain(){
      //返回学校页面
      this.$router.back()
    },
  }
}
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.v-note-wrapper{
  border: none;
  z-index: -10;
}

hr{
  opacity: .5;
}

.myTitle{
  span{
    font-weight: 600;
    font-size: 17px;
  }
}
.schoolIntroduce{
  width: 80%;
  margin: 20px auto;
  h3{
    margin-top: 30px;
  }
  h1{
    margin-top: 30px;
  }
  .myC{
    max-width: 100%;
    margin-top: 1vw;
    .introduce{
      margin-top: 40px;
      img{
        margin: 0 auto!important;
      }

    }
  }
  .schoolVedio{
    height: 33vw;
    img{
      width: 100%;
      height: 33vw;
      border-radius: 20px;
    }
  }
  .CollegeProfile{
    height: 33vw;
    background: #007DD11A;
    border-radius: 30px;
    padding: 10px 30px;
    .Info{
      display: flex;
      justify-content: space-between;
      margin-top:1vw;
      //span:nth-child(1){
      //  opacity: .7;
      //}
      div{
        font-weight: 600;
      }
    }
    .address{
      opacity: .8;
      margin-top: 2vw;
    }
  }
  .materials{
    //*{
    //  margin: 0;
    //  padding: 0;
    //}

    background: #007DD11A;
    border-radius: 20px;
    //height: 450px;
    //width: 350px;
    margin: 5vw auto 0;
    padding: 5px 2.3vw;
    h3{
      margin-bottom:1.5vw;
    }
    div{
      opacity: .8;
      text-align: center;
      margin-top: 2.5vw;
      p{
        margin-top: .8vw;
      }
    }
  }

  @media screen and (max-width: 500px) {
    h3{
      margin-top: 10px;
    }
    h1{
      margin-top: 10px;
    }
    .myC{
      max-width: 100%;
      margin-top: 20px;
    }
    .CollegeProfile{
      border-radius: 10px;
    }
    .myTitle img{
      display: none;
    }
  }
}
</style>
