<template>
    <div class="page-wrapper">
      <heroOne></heroOne>
        <!--Contact section start-->
        <div class="conact-section">

          <div class="ys">
            <div class="main-title">我们的优势</div>
            <div class="driver"></div>
            <div class="ysContent">
              <div class="myContent">
                <div class="myContent-img">
                  <img src="../../assets/myImage/ys1.png" alt="">
                </div>
                <div class="myContent-text">
                  <h3>新加坡本土留学机构</h3>
                  <p>掌握最新留学政策、录取信息 直对50+院校保证最佳留学方案</p>
                </div>
              </div>

              <div class="myContent">
                <div class="myContent-img">
                  <img src="../../assets/myImage/ys2.png" alt="">
                </div>
                <div class="myContent-text">
                  <h3>经验丰富顾问团队</h3>
                  <p>多年海外生活及行业经验 为每个家庭定制留学方案</p>
                </div>
              </div>

              <div class="myContent">
                  <div class="myContent-img">
                    <img src="../../assets/myImage/ys3.png" alt="">
                  </div>
                <div class="myContent-text">
                  <h3>一站式完整服务体验</h3>
                  <p>从规划到院校申请，签证办理到 落地入学，为您提供一站式服务</p>
                </div>
                </div>
            </div>
          </div>
          <div class="us">
            <div class="main-title">关于我们</div>
            <div class="driver" style="margin-bottom: 6vh"></div>
            <div class="usContent">
              <div class="usContent-left">
                <div>
                  <img src="../../assets/myImage/lv.png" alt="">
                </div>
              </div>
              <div class="usContent-right">
                <div class="usContent-right-text">
                  <div style="font-size: 1.3vw">始于2006年</div>
                  <p>新加坡智略国际教育私人有限公司（SagePath Global Education Pte. Ltd.）
                    是新加坡注册并受新加坡外交部及大使馆认证的留学咨询服务机构。创立初衷是解决留
                    学行业的信息不透明、服务不专业等问题。</p>

                  <p>团队以亲身经历为基础，从专业角度帮助每个家庭、每位留学生定制最佳的留学方案。
                    同时也提供全方位的国际教育咨询、院校申请、海外落地及培训辅导等服务。</p>

                  <p>自2006年成立以来，已帮助超过2000名来自亚洲各地的海外学生开启新加坡留学之旅。
                    旗下品牌有 “新加坡留学网” 教育资讯平台，“一键租房” 小程序等产品帮助留学生更顺利的开启留学生活。</p>
                </div>
              </div>
            </div>
          </div>
          <div class="framework">
            <div class="main-title">公司架构图</div>
            <div class="driver"></div>
            <div class="jiagoutu">
              <img src="../../assets/myImage/jiagou.png" alt="">
            </div>
          </div>
            <div class="address">
              <div class="main-title">我们的地址</div>
              <div class="driver"></div>
                <div class="row">

                  <div class="col-12" style="padding-left: 0;display: flex;justify-content: center">
                    <div class="col-lg-5 infos">
                      <div>智略国际教育（新加坡总部）</div>
                      <p>地址：1 North Bridge Road #06-01 High Street Centre Singapore 179094</p>
                      <p>联系人：Lea</p>
                      <p>微信号：EduSg11</p>
                      <p>电话：60150667</p>
                      <p>邮箱：hello@sagepaths.sg</p>
                    </div>
                    <!-- baidu map component -->
<!--                    <div class="col-lg-6">-->
<!--                      <HereMap  :center="position" />-->
<!--                    </div>-->
                    <div class="col-lg-6">
                      <baidu-map
                          class="bdwindow"
                          dragging="dragging"
                          :center="position"
                          :zoom="16"
                          :scroll-wheel-zoom="scroll"
                          :mapClick=false
                          style="height: 400px;width: 100%;border: 2px solid #faf2d5">
                        <bm-marker :position="position">
                          <bm-info-window :position="position" title="1 North Bridge Road #06-01 High Street Centre Singapore 179094" :show="show">
                          </bm-info-window>
                        </bm-marker>
                      </baidu-map>
                    </div>
                  </div>

                  <div class="col-12">
                    <hr>
                  </div>

                  <div class="col-12" style="padding-left: 0;display: flex;justify-content:center">
                    <div class="col-lg-5 infos">
                      <div>智略国际教育上海代表处</div>
                      <p>地址：上海市陆家嘴金融贸易区银城中路68号时代金融中心3302室</p>
                      <p>联系人：Lily Zu（区域总经理）</p>
                      <p>微信号：EduSg10</p>
                      <p>电话：15952032587（中国)</p>
                      <p>邮箱：lily@sagepaths.cn</p>
                    </div>
                    <!-- baidu map component -->
                    <div class="col-lg-6">
                      <baidu-map
                          class="bdwindow"
                          dragging="dragging"
                          :center="position2"
                          :zoom="16"
                          :scroll-wheel-zoom="scroll"
                          :mapClick=false
                          style="height: 450px;width: 100%;border: 2px solid #faf2d5">
                        <bm-marker :position="position2">
                          <bm-info-window :position="position2" title="上海市陆家嘴金融贸易区银城中路68号时代金融中心3302室" :show="show">
                          </bm-info-window>
                        </bm-marker>
                      </baidu-map>
                    </div>
                  </div>

                  <div class="col-12">
                    <hr>
                  </div>

                  <div class="col-12" style="padding-left: 0;display: flex;justify-content:center">
                    <div class="col-lg-5 infos">
                      <div>广东智略出国咨询有限公司</div>
                      <p>地址：广东省广州市天河区临江大道5号保利中心1702室</p>
<!--                      <p>联系人：Lily Zu（区域总经理）</p>-->
                      <p>微信号：TFFElaine</p>
                      <p>电话：+86 13822273149</p>
                      <p>邮箱：hello@sagepaths.sg</p>
                    </div>
                    <!-- baidu map component -->
                    <div class="col-lg-6">
                      <baidu-map
                          class="bdwindow"
                          dragging="dragging"
                          :center="position3"
                          :zoom="16"
                          :scroll-wheel-zoom="scroll"
                          :mapClick=false
                          style="height: 450px;width: 100%;border: 2px solid #faf2d5">
                        <bm-marker :position="position3">
                          <bm-info-window :position="position3" title="广东省广州市天河区临江大道5号保利中心1702室" :show="show">
                          </bm-info-window>
                        </bm-marker>
                      </baidu-map>
                    </div>
                  </div>

                </div>
            </div>
        </div>
        <!--Contact section end-->
    </div>
</template>

<script>
    import data from '../../data/contact.json'
    import HereMap from '@/components/HereMap'
    import heroOne from "@/components/sections/HeroOne";
    export default {
        components: {
            HereMap,
          heroOne
        },
        data () {
            return {
                data,
                position:{
                    lat: 1.29016, 
                    lng: 103.84959
                },
                position2:{
                   lat: 31.246842,
                   lng: 121.513237
                },
              position3:{
                lat: 23.120604,
                lng: 113.324369
              },
              scroll:true,
              zoom:17,
              show:true,
              dragging:true,
            }
        }
    };
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}

.conact-section{
  width: 85%;
  margin: 0 auto;
  .ysContent{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3vw;
    .myContent{
      width: 21vw;
      //height: 60vh;
      padding-bottom: 1.5vw;
      border-radius: 20px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      .myContent-img{
        width: 21vw;
        //height: 70%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .myContent-text{
        width: 12vw;
        margin: 1.5vw auto 0;
        text-align: center;
        p{
          opacity: .6;
        }
      }
    }
  }
  .infos{
    font-size: 1vw;
    font-weight: bold;
    margin-top: 1vw;
    div{
      font-size: 1.5vw;
      margin-bottom: 1vw;
    }
  }
  .usContent{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5vw;
    .usContent-left{
      width: 45%;
      div{
        //height: 55vh;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .usContent-right{
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      .usContent-right-text{
        width: 80%;
        p{
          height: 5vw;
          font-size: 1vw;
          margin-top: 1vw;
        }
      }
    }
  }
  .framework{
    margin-bottom: 5vw;
    .jiagoutu{
      //height: 60vh;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>