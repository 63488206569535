<template>
    <div class="main-container">



<!--        <Breadcrumb :items="items" title="Contact Us" />-->

        <ContactMainWrapper />



        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import ContactMainWrapper from '../components/sections/ContactMainWrapper'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            Breadcrumb,
            ContactMainWrapper,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Contact',
                        active: true
                    }
                ]
            }
        },
        metaInfo: {
            title: '新加坡留学网 - 联系我们',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>
<style scoped>
/**{*/
/*  outline: 1px solid red;*/
/*}*/
</style>

