<template>
  <div style="background-color: #F7F7F7">
    <div class="moreQuestions">
      <h3 @click="$router.back()"> < 返回</h3>
      <div class="main-title">留学问答</div>
      <div class="driver"></div>
      <div class="content">
        <div class="an" v-for="item of anData" :key="item.id">
          <div style="width: 5vw;height: 5vw;position: absolute;top: -2.5vw;left: 2.5vw;">
            <img v-if="item.wechat_user.avatar_url=='https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'" class="avatar" src="https://randomavatar.study65.com/" alt="">
            <img v-else class="avatar" :src="item.wechat_user.avatar_url" alt="">
          </div>
          <p>{{ item.questioner }}</p>
          <div class="title-group">
            <span class="topic">#{{item.classification}}:</span>
            <span class="title">{{item.question_content}}</span>
          </div>
          <div class="text">{{item.answer_content}}</div>
<!--          <div class="teacher">顾问老师:{{item.answerer.username}}</div>-->
        </div>
      </div>
      <div style="margin: 1.5vw auto 0" class="myBtn" @click="getNext">查看更多</div>
    </div>
  </div>
</template>

<script>
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";

export default {
  name: "moreQuestions",
  data(){
    return{
      anData:'',
      next:''
    }
  },
  created() {
    httpService.get(constants.encyclopedia).then(((data)=>{
      this.anData = data.body.results.slice(1,9)
      console.log(this.anData)
      this.count = data.body.count
      this.next = data.body.next
    }))
  },
  methods:{
    getNext(){//查看更多
      if (this.anData.length < this.count - 1){//加入判断，如果展示完了所有院校，那么就不再发送请求
        httpService.get(this.next).then((data) => {
          this.anData = this.anData.concat(data.body.results)
          this.next = data.body.next
          console.log(data.body.results)
        })
      }else {
        alert('没有更多问答了～～～')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
*{
  padding: 0;
  margin: 0;
}
.main-title{
  font-size: 35px;
  text-align: center;
  //margin-top: 2vw;
}
.driver{
  height: 4px;
  text-align: center;
  width: 50px;
  background-color:#007DD1 ;
  margin: 20px auto;
}
.moreQuestions{
  width: 80%;
  margin: 0 auto;
  padding: 2vw 0;
  h3{
    cursor: pointer;
  }
  .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .an{
    padding: 3vw 2vw 1vw;
    width: 23vw;
    //height: 41vh;
    margin-top:5vw;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    background-color: white;
  }
  .avatar{
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .title-group {
    margin: 5px 0;
    font-weight: bold;
    font-size: 20px;
    .topic {
      color: #007DD1;
    }
    .title {
      margin-left: 10px;
      font-size: .8vw;
      line-height: 1.5vw;
    }
  }
  .text {
    font-size: .8vw;
    color: #838383;
    margin: 5px 0;
    //height: 250px;
    overflow: hidden;
  }

  .teacher {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 1vw;
  }
}
</style>
