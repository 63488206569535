
<template>
  <div id="map">
    <!--In the following div the HERE Map will render-->
    <div
      id="mapContainer"
      style="height: 50vh; width: 100%"
      ref="hereMap"
    ></div>
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    center: Object,
    // center object { lat: 40.730610, lng: -73.935242 }
  },
  data() {
    return {
      platform: null,
      apikey: "UdTxveNPApCnQCNw0qzwOw_pjSQiCQMK80xMUx2zMHM",
      // You can get the API KEY from developer.here.com
    };
  },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey,
    });
    this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    initializeHereMap() {
      // rendering map

      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 16,
        center: this.center,
        // center object { lat: 40.730610, lng: -73.935242 }
      });

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // add UI
      var ui = H.ui.UI.createDefault(map, maptypes);
      // End rendering the initial map
      map.addObject(new H.map.Marker(this.center));
      // Create an info bubble object at a specific geographic location:
      var bubble = new H.ui.InfoBubble(
        this.center,
        {
          content: "<b>我们在这里等你哟!</b>",
        }
      );

      // Add info bubble to the UI:
      ui.addBubble(bubble);
    },
  },
};
</script>

<style scoped>
/**{*/
/*  border: 1px solid red;*/
/*}*/
#map {
  width: 100%;
  height: 50vh;
  min-width: 360px;
  text-align: center;
  margin: 1vw auto;
  background-color: #ccc;
}
</style>